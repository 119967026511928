<template>
  <div class="content-box-padding">
    <p class="line-text">
      南京浦蓝大气环境研究院在大气环境污染成因及治理、空气质量预报预警以及应对气候变化等国家和地区的重大需求方面起到了重要作用。大气环境方向相关团队围绕包括空气污染及其与天气气候相互作用在内的若干关键过程开展长期集成观测，在大气复合污染形成机理等方面获得一系列在国际上有重要影响的成果，为国家大气环境政策制定提供了重要支撑；发展的数值模型用于支撑国家和区域重大活动期间的空气质量预报保障，包括南京的青奥会，浙江乌镇的“世界互联网大会”、杭州G20峰会以及上海的世界进口博览会等。而绿色低碳发展方向相关的碳清单编制、碳中和路径规划以及碳产品价值评估服务等工作正在服务多个客户。
      <br />
      <br />
      <span class="font-weight"> 大气立体监测与大气超级站数据集成应用 </span
      >——2019年6月20日，科技部办公厅印发《国家野外科学观测研究站建设发展方案（2019－2025）》，以大气超级站为代表的地球系统多过程综合站建设正式成为国家基础设施并加快建设中。针对目前大气超级站数据应用较差、未能发挥出应有作用的普遍情况，研究院组织以教授牵头，博士后、博士等为主要研发力量的团队专注大气超级站数据集成解决方案，以期为科学问诊区域复合污染成因、推进城市生态文明建设提供技术支撑。项目基于对仪器科研级别运行的经验，形成一整套数据采集、数据库建立的标准业务流程。
      覆盖大气高端质谱仪器、颗粒物全粒径谱、地基遥感仪器等百万级别仪器的数据集成手段是项目技术亮点之一。从采样到标定，项目集成地为大气超站进行多设备多参数数据质控，从而长期稳定获取可用数据集是项目技术亮点之二。而对上述技术获取的数据集进行数据挖掘、数值模拟产生的再分析数据（机器学习，污染源解析，大气箱体化学模式等手段）及应用是项目最为核心的技术。项目目前预期的产品可用于获取污染过程中的关键特征污染物，剖析关键气象及化学过程，分析二次组分生成控制因素，最终得出污染成因并提出科学管控建议。目前，基于项目已有较为成熟的软件以及服务产品，中标省级项目两项，区级项目一项，支撑国家重点研发计划以及省级重大科研项目各一项。项目技术较为成熟，商业模式清晰，前景广阔。
    </p>
    <img
      src="~assets/image/about_us/关于我们1.png"
      alt="关于我们1"
      class="img-center"
      style="margin: 25px 0"
    />
    <div class="line-text">
      近年来，移动走航观测系统以其高效性和灵活性成为了大气环境监测的重要工具。研究院建设实现了集走航、大气空间（立体与水平空间）污染观测及输送通量观测的现代化走航观测车。车内配备大气颗粒物激光雷达、质子转移反应质谱（PTR-MS），紫外多组分在线监测仪（DV-3000）等目前较为先进的大型观测设备，在颗粒物与臭氧协同管控、工业园区应急溯源、空气质量保障等方面均有广泛应用。研究院基于自主研发的不同规模走航平台，搭载秒级响应设备，结合三维高精度电子地图，可实现边走边测，既能说清污染成因、污染来源、污染趋势，也能起到及时发现来源、定位污染源位置的作用，为管控和监督污染源排放发挥重要作用，真正可以做到“测管”协同，在环境监测和环境监察系统都有广泛应用。目前研究院的走航监测系统已经在常州、南京、深圳、青岛、福州等地开展监测溯源工作，也在多个空气质量会议保障期间崭露头角，发挥着重要作用。<br />
      目前，研究院已获得3A资质认证，顺利通过了ISO9001国际质量保证体系认证、ISO14001环境管理体系认证、ISO45001职业健康安全管理体系认证，作为环保协会和气象协会理事单位，公司累计获得知识产权20余项。
    </div>
    <img
      src="~assets/image/about_us/关于我们2.png"
      alt="关于我们2"
      class="img-center"
      style="margin: 30px 0"
    />
    <div class="line-text">
      大气高端质谱仪器研发及应用——大气高端质谱仪器（气溶胶质谱仪，VOCs以及痕量气体质谱仪）在世界各地得到了较好应用。科研单位使用其获取大气关键组分变化特征以研究自然界与人类活动相互作用问题。业务单位则依赖于其高性能的监测能力获取关键源信息，提供管控目标和对象。优秀的仪器应增加其在我国环境空气质量治理等方面的应用：一方面，提高国外进口高端仪器的应用效率，使其更好服务我国大气污染防治任务；另一方面，积极研发国产质谱仪器，掌握“卡脖子”核心技术，降低业务单位应用成本。应用方面，研究院有引进以及孵化公司专门从事相关国际领先的质谱仪器的销售以及应用开发；研发方面，研究院已孵化相应设备公司，仪器研发稳步开绿色低碳发展规划——通过融合城市级别的社会经济、能源消费、工业生产、排放水平等多源数据，开发了我国“省份-城市-重点源”三层嵌套的碳排放清单数据库。<br />
      设置包含能源消费结构、GDP增长与产业结构、人口和城镇化等不同参数的发展情景，并综合不同情景采取的不同路径后所带来的碳排放结果变化，绘制出低碳发展路线图。
    </div>

    <img
      src="~assets/image/about_us/关于我们3.png"
      alt="关于我们3"
      class="img-center"
      style="margin: 30px 0"
    />
  </div>
</template>
